import './styles.scss';
import React, {memo, useEffect, useState, useRef} from 'react';
import {toast, ToastContainer} from "react-toastify";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import CardFormDark from "../CardFormDark";
import {StripeError} from "@stripe/stripe-js/types/stripe-js/stripe";
import useAnalytics from "../../hooks/useAnalytics";
// import {fbq} from "@hutsoninc/gatsby-plugin-facebook-pixel";
import axios from "axios";

import {Link, navigate} from "gatsby";

import {
    LoadingGif,
    StripeImage2,
    onBoardCheck,
} from "../../images";

type Props = {
    onSuccess: () => void,
    tarifName: string,
    redirectLink: string
}

const PostRegPayment = ({onSuccess, tarifName, redirectLink}: Props) => {

    const cardFormRef = useRef();
    const [isCardValid, setCardValid] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState<string>('0.0');
    const {initAnalytics, saveEmail, saveSubType, saveTrialPrice} = useAnalytics();
    const [eventStatus, setEventStatus] = useState(false);

    const [isLoading, setLoading] = useState(false);
    const [email, setEmail] = useState(localStorage.getItem('email'));
    const [tarif, setTarif] = useState(localStorage.getItem('postRegTarif'));

    useEffect(() => {
        initAnalytics();

    }, []);

    useEffect(() => {
        if (!eventStatus) {
            // fbq("track", "Lead");
            setEventStatus(true);
        }
    }, [eventStatus]);

    const onCardValid = (status: boolean) => {
        setCardValid(status);
    }

    const stripe = loadStripe('pk_live_51KoA95Gxzah9dg4QawTHGGHiG7NHrUucf3QYIWD69yXAuPARtiheAKsB5Ht3EUmTx5WgxPbrgcX52djjCRq6n6V8000ZFScU5d');

    const handleTokenCreate = (token: string, ev: any) => {
        makePayment(token, ev);
    }

    const makePayment = (cardToken: string, ev?: any) => {
        if (cardToken && email && email.length) {
            setLoading(true);
            axios.post(`https://aeer.app/api/v1/payment/stripe`, {
                cardToken,
                email,
                priceId: tarif,
            }).then((res) => {
                saveSubType(email);
                // fbq("track", "Purchase");
                if (ev) {
                    ev.complete('success');
                }
                if (onSuccess) {
                    onSuccess();
                }
                navigate(redirectLink);
            }).catch(() => {
                ev.complete('fail');
                toast('Payment error, please check your credit card data', {
                    autoClose: 5000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                })
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    const onPayPress = async () => {
        setLoading(true);
        const card = await (cardFormRef.current as any).onPayClick();
        setLoading(false);

        if (card.error) {
            console.log('Error', card.error);
            toast(card.error.message, {
                autoClose: 5000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            })
            return;
        }

        makePayment(card.setupIntent.payment_method);
        
    }

    const handleCardError = (e: StripeError) => {
        console.log('e', e)
    }

    return (
        <>
            <div className="onBoardPaymentTitle">Enter your card details to start</div>
            {/* <div className="solTrialPaymentInputBox">
                <span className="solTrialPaymentInput withIcon">{email}</span>
            </div> */}
            {
                (tarifName === 'tarif1') && (
                    <div className="onBoardTarif">
                        <div 
                            className={`onBoardTarifItem active`} >
                            <div className="onBoardTarifIcon">
                                <img src={onBoardCheck} alt="" />
                            </div>
                            <p className="onBoardTarifTitle">$0.00 and after trial $44.99/month</p>
                            <p className="onBoardTarifTextCheck">Tools to manage your life</p>
                            <p className="onBoardTarifTextCheck">Access to more than 100+ courses </p>
                            <p className="onBoardTarifTextCheck">Chat with educational assistant Mr. AEER</p>
                            <div className="onBoardTarifBest">Complex approach + Ai Chat</div>
                        </div>
                    </div>
                )
            }
            {
                (tarifName === 'tarif2') && (
                    <div className="onBoardTarif">
                        <div 
                            className={`onBoardTarifItem active`}>
                            <div className="onBoardTarifIcon">
                                <img src={onBoardCheck} alt="" />
                            </div>
                            <p className="onBoardTarifTitle">$0.00 and after trial $34.99/month</p>
                            <p className="onBoardTarifTextCheck">Tools to manage your life</p>
                            <p className="onBoardTarifTextCheck">Access to more than 100+ courses</p>
                            <div className="onBoardTarifBest">Complex approach</div>
                        </div>
                    </div>
                )
            }
            {
                (tarifName === 'tarif3') && (
                    <div className="onBoardTarif">
                        <div 
                            className={`onBoardTarifItem active`}>
                            <div className="onBoardTarifIcon">
                                <img src={onBoardCheck} alt="" />
                            </div>
                            <p className="onBoardTarifTitle">$0.00 and after trial $5.99/month</p>
                            <p className="onBoardTarifTextCheck">Tools to manage your life</p>
                            <div className="onBoardTarifBest">introductory</div>
                        </div>
                    </div>
                )
            }
            <div className="paypage">
                {/* <div className="onBoardRegForm postRegForm">
                    <span className="onBoardRegFormTitle">To continue please enter your email address</span>
                    
                    <div className="onBoardQuesInput onBoardQuesInputEmail">
                        <input 
                            type="email" 
                            onChange={(e) => valideOnKeyup(e.target.value)} 
                            className={`${isEmailValid ? 'error' : ''}`}
                            placeholder="Email" 
                            />
                    </div>
                </div> */}
                <div className="paypageWidget">
                    <Elements stripe={stripe}>
                        <CardFormDark
                            trialPrice={Number.parseFloat(paymentMethod)}
                            onGetCardToken={handleTokenCreate}
                            onCardError={handleCardError}
                            ref={cardFormRef}
                            onValidationChange={onCardValid}/>
                    </Elements>
                </div>
                <div className="paypageStripe dark">
                    <img src={StripeImage2} alt=""/>
                    <span>Your data is 100% secure. <br/> The payment is processed by Stripe</span>
                </div>
            </div>
            <div className="onBoardBottom">
                <button 
                    id={`onboard-payment`}
                    className="onBoardBtn" 
                    disabled={!isCardValid && isLoading}
                    onClick={onPayPress}>{(!isCardValid && isLoading) ? 'Wait..' : 'Pay $0.00'}</button>
            </div>

            {isLoading && (
                <div className="loaderWrap">
                    <div className="loader">
                        <img width={44} src={LoadingGif} alt=""/>
                    </div>
                </div>
            )}

            <ToastContainer/>
        </>
    )
}

export default memo(PostRegPayment);
