import './styles.scss';
import React, {memo, useEffect, useState} from 'react';
import ReactInputVerificationCode from 'react-input-verification-code';

import {Link, navigate} from "gatsby";
import {toast, ToastContainer} from "react-toastify";
import useAnalytics from "../../hooks/useAnalytics";

import {
    reset
} from "../../images";

type Props = {
    id: string
}

const PostRegEmail = ({id}: Props) => {

    // const isDisabled = false;
    const [isDisabled, setIsDisabled] = useState(true);
    const [email, setEmail] = useState('');
    const {initAnalytics, saveEmail, saveSubType, saveTrialPrice} = useAnalytics();

    const validateEmail = () => {
        if (email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )){
            saveEmail(email);
            localStorage.setItem('email', email);
            navigate('/post-reg/loading-data');
        } else{
            toast('Invalid e-mail address', {
                autoClose: 5000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }

    const valideOnKeyup = (value:string) => {
        setEmail(value);
        if(value.length > 0){
            setIsDisabled(false);
        } else{
            setIsDisabled(true);
        }
    }

    return (
        <>
            <div className="onBoardProgress">
                <div className={`onBoardProgressItem active`}></div>
                <div className={`onBoardProgressItem active`}></div>
                <div className={`onBoardProgressItem active`}></div>
                <div className={`onBoardProgressItem active`}></div>
            </div>
            <div className="onBoardRegForm">
                <span className="onBoardRegFormTitle">Email Address</span>
                <p className="onBoardRegFormText">To continue please enter your email address</p>
                <div className="onBoardQuesInput onBoardQuesInputEmail">
                    <input 
                        type="email" 
                        onChange={(e) => valideOnKeyup(e.target.value)} 
                        placeholder="Email" 
                        // onKeyUp={(e) => onInputKeyup(e.currentTarget.value)} 
                        />
                </div>
            </div>
            <div className="onBoardBottom">
                <button 
                    id="pageCorp-corp-second-SignUpBtn"
                    className={`onBoardBtn ${isDisabled ? 'disabled' : ''}`}
                    onClick={() => validateEmail()}>Continue</button>
                {/* <Link to={`/corp-main-onboard/confirm-email`} className={`onBoardBtn ${isDisabled ? 'disabled' : ''}`}>Continue</Link> */}
            </div>

            <ToastContainer/>
        </>
    )
}

export default memo(PostRegEmail);
