import '../styles/global.scss';
import '../styles/pages/post-reg.scss';

import {HeadFC} from "gatsby";

import React, {useEffect, useState, useMemo} from 'react';
import {Link, navigate} from "gatsby";

import {
    arrowLeft,
    checkCircle2,
    bell,
    clockCircle,
    infoCircle,
    manTrial,
    onBoardCheck,
    postReg1,
    postReg2,
    postReg3,
    postReg4,
    postReg5
} from "../images";
import useAnalytics from "../hooks/useAnalytics";
import OnBoardQuestion from '../components/OnBoardQuestion';
import OnBoardLoading from '../components/OnBoardLoading';
import PostRegScreen from '../components/PostRegScreen';
import PostRegPayment from '../components/PostRegPayment';
import PostRegEmail from '../components/PostRegEmail';

type Props = {
    id: string,
}

const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const PostRegPage = ({id}: Props) => {
    const {initAnalytics, saveEmail, saveSubType} = useAnalytics();

    useEffect(() => {
        initAnalytics()
    }, []);

    const [screenShots, setScreenShots] = useState([
        {
            id: 1,
            image: postReg1,
            name: 'main-first',
            title: 'Lets customize aeer app for you',
            subtitle: 'Let us make the experience of using our tools best for you ',
            text: 'The comprehensive approach includes learning, career and financial literacy development, time and task management, and goal achievement',
            btnText: 'let’s start',
            btnNext: '/post-reg/main-second'
        },
        {
            id: 2,
            image: postReg2,
            name: 'main-second',
            title: 'Lets customize aeer app for you',
            subtitle: 'Let us make the experience of using our tools best for you ',
            text: 'Improve soft skills with interactive courses. Enhance communication, leadership, and teamwork abilities with a user-friendly platform. Take charge of personal growth and succeed today.',
            btnText: 'Got it',
            btnNext: '/post-reg/main-third'
        },
        {
            id: 3,
            image: postReg3,
            name: 'main-third',
            title: 'Lets customize aeer app for you',
            subtitle: 'Let us make the experience of using our tools best for you ',
            text: 'Take control of your personal finances with an intuitive interface. Create budgets, track expenses, and manage future payments. Take responsibility for your financial future.',
            btnText: 'Got it',
            btnNext: '/post-reg/main-forth'
        },
        {
            id: 4,
            image: postReg4,
            name: 'main-forth',
            title: 'Lets customize aeer app for you',
            subtitle: 'Let us make the experience of using our tools best for you ',
            text: 'Organize tasks, track goals, and achieve success with an intuitive interface. Stay motivated and focused on personal and professional projects with ease.',
            btnText: 'Got it',
            btnNext: '/post-reg/main-fifth'
        },
        {
            id: 5,
            image: postReg5,
            name: 'main-fifth',
            title: 'Lets customize aeer app for you',
            subtitle: 'Let us make the experience of using our tools best for you ',
            text: 'Educational AI-assistant <span>Mr. AEER</span> will help to develop skills, fulfill tasks, reach goals, manage finances, design future and many more.',
            btnText: 'Continue',
            btnNext: '/post-reg/select-age'
        },
    ]);

    const renderScreensProgress = (currCues:number) => {
        return screenShots.map((key) => {
            return <div className={`postRegProgressItem ${(key.id <= currCues) ? 'active' : ''}`}></div>
        })
    }

    const renderScreens = () => {
        return screenShots.map((key) => {
            if(id === key.name){
                return <React.Fragment key={`postRegScreen-${key.id}`}>
                    <div className="postRegProgress">
                        {renderScreensProgress(key.id)}
                    </div>
                    <PostRegScreen data={key} />
                </React.Fragment>
            }
        })
    }

    const [questions, setQuestions] = useState([
        {
            id: 1,
            name: 'select-age',
            type: 'radio',
            title: "What is your age?",
            answers: [
                '13-17',
                '18-24',
                '25-34',
                '35-44',
                '45-54',
                '55+'
            ],
            next: '/post-reg/want-to-achive',
        },
        {
            id: 2,
            name: 'want-to-achive',
            type: 'checkbox',
            title: "What you want to achieve with AEER <br /> (Choose as many as you like)",
            answers: [
                'I want to use a complex approach which includes all the AEER tools and courses',
                'I want to control finances and raise my financial literacy',
                'I want to set tasks, goals and achieve them',
                'I want to chat with AI and receive answers for all my questions',
                'I want to study soft-skills with AEER interactive courses'
            ],
            next: '/post-reg/self-development',
        },
        {
            id: 3,
            name: 'self-development',
            type: 'radio',
            title: "How much time are you ready to commit to self-development per day",
            answers: [
                '14-18 minutes',
                '24 minutes',
                'less than 1 hour',
                'More 1 hour'
            ],
            next: '/post-reg/register-email',
        },
        {
            id: 4,
        }
    ]);

    const [tarif, setTarif] = useState('tarif2');
    const [isFinish, setIsFinish] = useState(false);

    const chooseTarif = (tarifName:string) => {
        setTarif(tarifName);
        confirmTarif(tarifName);
        // navigate('/post-reg/postreg-payment');
    }

    const confirmTarif = (tarifName:string) => {
        if(tarifName === 'tarif1'){
            localStorage.setItem('postRegTarif', 'price_1MlCV6Gxzah9dg4QpR5NU5sD');
        } else if(tarifName === 'tarif2'){
            localStorage.setItem('postRegTarif', 'price_1MlCVaGxzah9dg4Q7xPSqSXB');
        } else if(tarifName === 'tarif3'){
            localStorage.setItem('postRegTarif', 'price_1MlCW3Gxzah9dg4QtXLY8DJl');
        }
    }

    const onSuccessPayment = () => {
        setIsFinish(true)
    }

    const renderNavigation = () => {
        return <div className="onBoardNavigation">
            <button className="onBoardPrev" onClick={() => navigate(-1)}>
                <img src={arrowLeft} alt="" />
            </button>
        </div>
    };

    const renderQuestionPage = (name:string) => {
        return questions.map( (key) => {
            if(key.name === name){
                return (
                    <React.Fragment key={`onboard-${key.id}`}>
                        {renderNavigation()}
                        <OnBoardQuestion key={`onboard-${key.id}`} data={key} array={questions} />
                    </React.Fragment>
                );
            }
        })
    }
    

    const renderPages = () => {
        switch(id) {
            case 'main-first':
                return <div className="onBoardBox">
                    {renderScreens()}
                </div>;
            case 'main-second':
                return <div className="onBoardBox">
                    {renderScreens()}
                </div>;
            case 'main-third':
                return <div className="onBoardBox">
                    {renderScreens()}
                </div>;
            case 'main-forth':
                return <div className="onBoardBox">
                    {renderScreens()}
                </div>;
            case 'main-fifth':
                return <div className="onBoardBox">
                    {renderScreens()}
                </div>;
            case 'select-age':
                return <div className="onBoardBox">
                    {renderQuestionPage('select-age')}
                </div>;
            case 'want-to-achive':
                return <div className="onBoardBox">
                    {renderQuestionPage('want-to-achive')}
                </div>;
            case 'self-development':
                return <div className="onBoardBox">
                    {renderQuestionPage('self-development')}
                </div>;
            case 'register-email':
                return <div className="onBoardBox">
                    {renderNavigation()}
                    <PostRegEmail id={id} />
                </div>
            case 'loading-data':
                return <div className="onBoardBox loadcenter">
                    <OnBoardLoading setLink={'/post-reg/try-trial'} />
                </div>
            case 'try-trial':
                return <div className="onBoardBox">
                    {renderNavigation()}
                    <p className="onBoardAdvasTitle">Try AEER Platform for 7 days <br /> for free</p>
                    <div className="onBoardSteps">
                        <div className="onBoardStep">
                            <div className="onBoardStepIcon darkline">
                                <img src={checkCircle2} alt="" />
                            </div>
                            <span className="onBoardStepTitle">Today</span>
                            <p className="onBoardStepSubtitle">No payment now. Enjoy full access to <br /> AEER Platform for free</p>
                        </div>
                        <div className="onBoardStep">
                            <div className="onBoardStepIcon solidline">
                                <img src={bell} alt="" />
                            </div>
                            <span className="onBoardStepTitle">Day 5</span>
                            <p className="onBoardStepSubtitle">Get a reminder about when your free trial <br /> will end</p>
                        </div>
                        <div className="onBoardStep">
                            <div className="onBoardStepIcon dashline">
                                <img src={clockCircle} alt="" />
                            </div>
                            <span className="onBoardStepTitle">Day 7</span>
                            <p className="onBoardStepSubtitle">On this day your trial will convert to full price <br /> unless cancelled </p>
                        </div>
                        <div className="onBoardStep">
                            <div className="onBoardStepIcon">
                                <img src={infoCircle} alt="" />
                            </div>
                            <span className="onBoardStepTitle">Don’t worry - we’ll refund you if you forget!</span>
                        </div>
                    </div>
                    
                    <div className="onBoardBottom">
                        <Link to="/post-reg/trial-tarif" className="onBoardBtn">I’m in!</Link>
                    </div>
                </div>
            case 'trial-tarif':
                return <div className="onBoardBox">
                    {renderNavigation()}
                    <p className="onBoardAdvasTitle">Try AEER Platform in 7-days <br /> free trial</p>
                    <div className="onBoardRemark">
                        We’ll <b>remind</b> you before your trial end, and <br /> <b>refund</b> you if you forget to cancel
                    </div>
                    <div className="onBoardTarif">
                        <div 
                            className={`onBoardTarifItem ${(tarif === 'tarif1') ? 'active' : ''}`} 
                            onClick={() => chooseTarif('tarif1')}>
                            <div className="onBoardTarifIcon">
                                <img src={onBoardCheck} alt="" />
                            </div>
                            <p className="onBoardTarifTitle">$0.00 and after trial $44.99/month</p>
                            <p className="onBoardTarifTextCheck">Tools to manage your life</p>
                            <p className="onBoardTarifTextCheck">Access to more than 100+ courses </p>
                            <p className="onBoardTarifTextCheck">Chat with educational assistant Mr. AEER</p>
                            <div className="onBoardTarifBest">Complex approach + Ai Chat</div>
                        </div>
                        <div 
                            className={`onBoardTarifItem ${(tarif === 'tarif2') ? 'active' : ''}`} 
                            onClick={() => chooseTarif('tarif2')}>
                            <div className="onBoardTarifIcon">
                                <img src={onBoardCheck} alt="" />
                            </div>
                            <p className="onBoardTarifTitle">$0.00 and after trial $34.99/month</p>
                            <p className="onBoardTarifTextCheck">Tools to manage your life</p>
                            <p className="onBoardTarifTextCheck">Access to more than 100+ courses</p>
                            <div className="onBoardTarifBest">Complex approach</div>
                        </div>
                        <div 
                            className={`onBoardTarifItem ${(tarif === 'tarif3') ? 'active' : ''}`} 
                            onClick={() => chooseTarif('tarif3')}>
                            <div className="onBoardTarifIcon">
                                <img src={onBoardCheck} alt="" />
                            </div>
                            <p className="onBoardTarifTitle">$0.00 and after trial $5.99/month</p>
                            <p className="onBoardTarifTextCheck">Tools to manage your life</p>
                            <div className="onBoardTarifBest">introductory</div>
                        </div>
                    </div>
                    <div className="onBoardComment">
                        <div className="onBoardCommentInside">
                            <div className="onBoardCommentIcon">
                                <img src={manTrial} alt="" />
                            </div>
                            <p className="onBoardCommentTitle">Charles Holiday</p>
                            <p className="onBoardCommentText">“I’ve been using AEER for a month now. Thanks to the individually selected set of courses and tools I've got rid of the habits  which led to the procrastination, failures and dissatisfaction with my life. Now I know what to do and how to realize my plans.”</p>
                        </div>
                    </div>
                    <div className="onBoardBottom">
                        <Link to="/post-reg/postreg-payment" onClick={() => confirmTarif(tarif)} className="onBoardBtn">Pay $0.00</Link>
                    </div>
                </div>
            case 'postreg-payment':
                return <div className="onBoardBox">
                    {renderNavigation()}
                    <PostRegPayment tarifName={tarif} redirectLink="/solutions/finish" onSuccess={onSuccessPayment} />
                </div>
            default:
                return <div>This page doesn`t exist</div>;
        }
    }

    return (
        <>
            <div className="onBoard postReg">
                {renderPages()}
            </div>
        </>
    )
}

export default PostRegPage;

export const Head: HeadFC = () => (
    <>
        <title>Quiz - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);
