import './styles.scss';
import React, {memo, useEffect, useState} from 'react';

import {Link, navigate} from "gatsby";
import parse from 'html-react-parser';

import {
    onBoardCheckSquare,
    onBoardCheck,
    arrowLeft,
    dotsMore,

} from "../../images";

type Props = {
    data: {
        id: number,
        name: string,
        type: string,
        title: string,
        answers: Array<string>,
        next: string,
    },
    array: Array<any>
}

const OnBoardQuestion = ({data, array}: Props) => {

    // const [isLoading, setLoading] = useState(true);

    const [activeRadio, setActiveRadio] = useState(-1);
    const [isDisabled, setIsDisabled] = useState(true);

    const renderProgress = (currIndex:number) => {
        return <div className="onBoardProgress">
            {
                array.map((key, index) => (
                    <div key={`progress-${index}`} className={`onBoardProgressItem ${(key.id <= currIndex) ? 'active' : ''}`}></div>
                ))
            }
        </div>
        
    };

    const onRadioSelect = (index:number) => {
        setActiveRadio(index)
        setIsDisabled(false);
    }

    const onInputKeyup = (value:string) => {
        if(value.length > 0){
            setIsDisabled(false);
        } else{
            setIsDisabled(true);
        }
    }

    const renderAnswers = (array:Array<string>, type:string) => {

        return array.map((key, index) => {
            if(type === 'checkbox'){
                return <div key={`answ-${index}`} 
                    className={`onBoardAnswer onBoardAnswer${type}`}
                    onClick={() => onRadioSelect(index)}>
                    <input type="checkbox" name={`checkbox[]`} id={`checkbox-${index}`} />
                    <label htmlFor={`checkbox-${index}`}>
                        <div className="onBoardAnswerIcon">
                            <img src={onBoardCheckSquare} alt="" />
                        </div>
                        <span className="onBoardAnswerText">{key}</span>
                    </label>
                </div>
            } else{
                return <div key={`answ-${index}`} 
                    className={`onBoardAnswer onBoardAnswer${type}`} 
                    onClick={() => onRadioSelect(index)}>
                    <input type="radio" name={`radio`} id={`radio-${index}`} />
                    <label htmlFor={`radio-${index}`}>
                        <div className="onBoardAnswerIcon">
                            <img src={onBoardCheck} alt="" />
                        </div>
                        <span className="onBoardAnswerText">{key}</span>
                    </label>
                </div>
            }
        });
    }

    const renderQuestion = (type:string, answers:any) => {
        switch(type) {
            case 'field':
                return <div className="onBoardQuestion">
                    <div className="onBoardQuesInput">
                        <input type="text" placeholder="Enter please your name" onKeyUp={(e) => onInputKeyup(e.currentTarget.value)} />
                    </div>
                </div>;
            case 'radio':
                return <div className="onBoardQuestion">
                    <div className="onBoardAnswers">
                        {renderAnswers(answers, type)}
                    </div>
                </div>;
            case 'checkbox':
                return <div className="onBoardQuestion">
                    <div className="onBoardAnswers">
                        {renderAnswers(answers, type)}
                    </div>
                </div>;
        }
    }

    return (
        <>
            {renderProgress(data.id)}

            <div className="onBoardHead">
                <div className="onBoardQuesNumb">Question {data.id} of {array.length}</div>
                <p className="onBoardQuesTitle">{parse(data.title)}</p>
            </div>
            {renderQuestion(data.type, data.answers)}
            <div className="onBoardBottom">
                <Link to={`${data.next}`} className={`onBoardBtn ${isDisabled ? 'disabled' : ''}`}>Continue</Link>
            </div>
        </>
    )
}

export default memo(OnBoardQuestion);
