import './styles.scss';
import React, {memo, useEffect, useState, useRef} from 'react';

import {Link, navigate} from "gatsby";
import parse from 'html-react-parser';

import {
    postRegNext,
    postRegPrev
} from "../../images";

type Props = {
    data: {
        id: number,
        image: string,
        title: string,
        subtitle: string,
        text: string,
        btnText: string,
        btnNext: string,
    }
}

const PostRegScreen = ({data}: Props) => {

    const prevBtnClick = () => {
        navigate(-1);
    }

    return (
        <>
            <div className="postRegTitle">{parse(data.title)}</div>
            <div className="postRegSubtitle">{parse(data.subtitle)}</div>
            <div className="postRegScreen">
                <div className="postRegNavig">
                    {
                        (data.id !== 1) && (
                            <div className="postRegNavigPrev" onClick={() => prevBtnClick()}>
                                <img src={postRegPrev} alt="" />
                            </div>
                        )
                    }
                    <Link to={data.btnNext} className="postRegNavigNext">
                        <img src={postRegNext} alt="" />
                    </Link>
                </div>
                <div className="postRegImage">
                    <img src={data.image} alt="" />
                </div>
                <div className="postRegText">
                    <p>{parse(data.text)}</p>
                </div>
            </div>

            <div className="onBoardBottom">
                <Link to={data.btnNext} className="onBoardBtn">{data.btnText}</Link>
                {/* <p className="onBoardLogin">
                    Already have an account: 
                    <a href=""> login</a>
                </p> */}
            </div>
        </>
    )
}

export default memo(PostRegScreen);
